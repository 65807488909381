import styled from "styled-components"
import breakpoints from "./breakpoints"
import { bold16 } from "./typography";

const StyledSiteNotificationTimeDuration = styled.div`
    background: var(--red-mid);
    padding: 0.8rem 0;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    transition: height 0.4s linear;
    width: 100%;
    
    visibility: ${props => props.isNotification ? 'visible' : 'hidden'};
    opacity: ${props => props.isNotification ? '1' : '0'};
    transition: opacity 250ms ease-in;

    @media screen and (min-width: ${breakpoints.sm}px) {
        padding: 1.6rem 0;
    }
    @media screen and (min-width: ${breakpoints.sm}px) {
        padding: 0.8rem 0;
    }
    p {
        color: #fff;
        text-align: center;
        padding-right: 3.2rem;
        padding-left: 1.6rem;
        font-weight: 700;

        @media screen and (min-width: ${breakpoints.md}px) {
            text-align: center;
            padding-right: 0;
        }

        a {
            display: inline-block;
            color: var(--white);
            text-align: center;
            ${bold16}
            // width: calc(100% + 16px);
            border-radius: 1rem;
            border: 2px solid #fff;
            padding: 0.2rem 0.8rem;
        }
    }
`

export {
    StyledSiteNotificationTimeDuration
}